import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrEventReportDetailsView',
  computed: {
    ...mapState('event', ['eventAllContent']),
    ...mapState('report', ['reportSnapshot', 'reportSnapshotResult', 'reportSnapshotResultData'])
  }
})
export default class GtrEventReportDetailsView extends Vue {
  loading = true;
  reportSnapshotResultReport: Record<string, any> = {};
  reportSnapshot!: Record<string, any>;
  reportSnapshotResult!: Record<string, any>;
  reportSnapshotResultData!: Array<Array<any>>;
  event_uuid = this?.$route?.params?.event_uuid;
  report_uuid = this?.$route?.params?.report_uuid;
  rowGroup = []; // need a non-reactive variable
  rowsPerPage = 100;
  currentReportPage = 1;
  export_data = [];

  pieChartOptions = {
    responsive: false,
    hoverBorderWidth: 20
  };

  barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    hoverBorderWidth: 20
  };

  polarAreaChartOptions = {
    responsive: false,
    hoverBorderWidth: 20
  };

  data () {
    return {
      event_all_content: null,
      payment_processor_currency: null,
      showExportDialog: false,
      invoiceData: {
        invoiceNumberIndexes: {},
        invoiceUrls: {}
      }
    }
  }

  // before the component gets mounted clean out report data.
  async created () {
    this.$store.commit('report/SET_REPORT_SNAPSHOT', {})
    this.$store.commit('report/SET_REPORT_SNAPSHOT_RESULT', {})
    this.$store.commit('report/SET_REPORT_SNAPSHOT_RESULT_DATA', [])
  }

  async mounted () {
    this.reportSnapshotResultReport = {}
    this.loading = true
    await this.fetchData()
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  get eventTimeZone (): string {
    return this.$store.state.event.event.timezone
  }

  get report () {
    return this.reportSnapshotResultReport?.report
  }

  get reportType () {
    return this.reportSnapshotResultReport?.report?.report_type ?? ''
  }

  /**
   * Returns an object whose indexes are page numbers and whose values are
   * objects whose indexes are the original row index and whose values are the row data.
   */
  get reportPages () {
    const data = this.export_data
    const pages: any = {}
    if (Array.isArray(data) && data.length) {
      let page = 1
      pages[page] = {}
      for (let i = 0; i < data.length; i++) {
        pages[page][i] = data[i]
        if (Math.ceil((i + 1) % this.rowsPerPage) === 0) {
          pages[++page] = {}
        }
      }
    }
    return pages
  }

  get numberOfReportPages (): number {
    if (Array.isArray(this.export_data)) {
      return Math.ceil(this.export_data.length / this.rowsPerPage)
    }
    return 0
  }

  get reportPageNumbers (): any[] {
    const pageNumbers: any[] = []
    for (let p = 1; p <= this.numberOfReportPages; p++) {
      pageNumbers.push({ text: p.toString(), value: p })
    }
    return pageNumbers
  }

  prevReportPage () {
    if (this.currentReportPage === 1) {
      this.currentReportPage = this.numberOfReportPages
    } else {
      this.currentReportPage--
    }
  }

  nextReportPage () {
    if (this.currentReportPage === this.numberOfReportPages) {
      this.currentReportPage = 1
    } else {
      this.currentReportPage++
    }
  }

  get attendeeUuids () {
    return this.reportSnapshotResultReport.row_to_uuid_map
  }

  get isNotSystemGenerated (): boolean {
    return ![
      'Snapshot Report',
      'Lead Retrieval Report',
      'Badges Printed by Registration Type'
    ].includes(this.report?.name)
  }

  get isReportEditable (): boolean {
    return this.reportSnapshotResultReport?.report?.editable ?? false
  }

  get titleTooltip (): string {
    switch (this.report?.name) {
      case 'Badges Printed By Hour':
        return 'This report shows prints per hour from 24 hours before event start time until end of event.'
      default:
        return ''
    }
  }

  rowGroupClass (): string {
    return 'row-group-' + this.rowGroup.length
  }

  rowGroupHeaderClass (): string {
    this.rowGroup.length++
    return 'row-group-header'
  }

  isItemADate (item: any): boolean {
    return (new Date(item)).toString() !== 'Invalid Date' && /^\d{4}(-|\/)(0?[1-9]|1[012])(-|\/)(0?[1-9]|[12][0-9]|3[01]).*/.test(item)
  }

  toggleClass (element: Element, cssClass: string) {
    if (element.classList.contains(cssClass)) {
      element.classList.remove(cssClass)
    } else {
      element.classList.add(cssClass)
    }
  }

  toggleRowGroupVisibility (event: Event) {
    const rowHeader = event.currentTarget as HTMLElement
    const rowGroupSelector = '.row-group-' + rowHeader.dataset.rowGroup
    const rowGroupHiddenClass = 'collapsed'

    this.toggleClass(rowHeader, rowGroupHiddenClass)
    document.querySelectorAll(rowGroupSelector).forEach(row => {
      this.toggleClass(row, rowGroupHiddenClass)
    })
  }

  @Watch('reportSnapshotResult', { immediate: true })
  async onReportSnapshotResultChange (payload: any) {
    if (payload) {
      if (payload.status === 'COMPLETE') {
        // If the report shows invoice numbers, retrieve the invoice URLs from eventStats and match them to their invoice numbers.
        const reportSnapshotResult = payload
        const invoiceNumberIndexes: number[] = []
        const zoneRows: any[][] = []
        const participantUuids: any[][] = []
        if (Array.isArray(reportSnapshotResult?.export_headings)) {
          const iIndex = reportSnapshotResult.export_headings.indexOf('Invoice Number')
          if (iIndex >= 0) {
            this.$data.invoiceData.invoiceNumberIndexes.spreadsheet = iIndex
            invoiceNumberIndexes.push(iIndex)
            zoneRows.push(reportSnapshotResult.export_data)
            participantUuids.push(reportSnapshotResult.row_to_uuid_map)
          }
        } else if (Array.isArray(reportSnapshotResult.zone_data)) {
          reportSnapshotResult.zone_data.forEach(zone => {
            if (Array.isArray(zone.export_headings)) {
              const iIndex = zone.export_headings.indexOf('Invoice Number')
              if (iIndex >= 0) {
                this.$data.invoiceData.invoiceNumberIndexes[zone.item.field] = iIndex
                invoiceNumberIndexes.push(iIndex)
                zoneRows.push(zone.export_data)
                participantUuids.push(zone.record_id_map)
              }
            }
          })
        }
        if (invoiceNumberIndexes.length) {
          try {
            const eventStats = await this.$store.dispatch('event/getEventStats', this.event_uuid)
            if (eventStats.data?.invoices) {
              if (zoneRows.length && participantUuids.length) {
                // participantInvoiceNumbers keys are participant UUIDs whose values are invoice numbers
                const participantInvoiceNumbers: any = {}
                for (let zoneIndex = 0; zoneIndex < zoneRows.length; zoneIndex++) {
                  const rows = zoneRows[zoneIndex]
                  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
                    // This can overwrite the invoice number for a given participant UUID, but that's ok since each participant can only have one invoice.
                    participantInvoiceNumbers[participantUuids[zoneIndex][rowIndex].uuid] = rows[rowIndex][invoiceNumberIndexes[zoneIndex]]
                  }
                }
                const invoices: any[] = eventStats.data.invoices
                // invoiceUrls keys are invoice numbers whose values are their URLs
                const invoiceUrls = invoices.reduce((accumulator, currentInvoice) => {
                  accumulator[participantInvoiceNumbers[currentInvoice.participant_uuid]] = currentInvoice.url
                  return accumulator
                }, {})
                this.$data.invoiceData.invoiceUrls = invoiceUrls
              }
            }
          } catch (error) {
          }
        }
        this.loading = false
      }
      if (payload.status === 'FAILED') {
        this.loading = false
        Container.get(Notification).error('There was an error loading the report, please contact support')
      }
      this.reportSnapshotResultReport = payload
    }
  }

  @Watch('reportSnapshotResultData', { immediate: true })
  async onReportSnapshotResultDataChange (payload: any) {
    if (payload) {
      this.export_data = payload
    }
  }

  private async fetchData () {
    try {
      await this.$store.dispatch('report/getReportSnapshot', {
        event_uuid: this.event_uuid,
        report_uuid: this.report_uuid
      })
    } catch (error) {
      Container.get(Notification).error(error)
    }
  }

  handleOpenExportDialog () {
    this.$data.showExportDialog = true
    setTimeout(() => {
      this.handleCloseExportDialog()
    }, 5000)
  }

  handleCloseExportDialog () {
    this.$data.showExportDialog = false
  }
}
